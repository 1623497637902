import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {Platform} from '@ionic/angular';
import {AuthorizationService} from "./_services/authorization.service";
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {NotificationService} from "./_services/notification.service";
import {UserService} from "./_services/user.service";
import {Device} from '@ionic-native/device/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {EnumsService} from "./_services/enums.service";
import {StatusBar} from '@ionic-native/status-bar/ngx';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    deviceID = '';
    logged = false;
    isLogin = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private authorizationService: AuthorizationService,
        private fcm: FCM,
        private platform: Platform,
        private notificationService: NotificationService,
        private userService: UserService,
        private androidPermissions: AndroidPermissions,
        private device: Device,
        private _enumsService: EnumsService,
        private statusBar: StatusBar
    ) {
        this.isLogin = authorizationService.getLoginState();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data && child.snapshot.data.title) {
                        return child.snapshot.data.title;
                    } else {
                        return null;
                    }
                }
                return null;
            })
        ).subscribe((data: any) => {
            if (data) {
                this.titleService.setTitle(data);
            }
        });
        //
        this.platform.ready().then(async () => {
            this.getPermission();
        });
        //
        const at = window.localStorage.getItem('at');
        if (at) {
            this.logged = true
            this.authorizationService.setToken(at).then(() => {
                this.Init();
            })
        }
    }

    Init() {
        this.platform.ready().then(async () => {
            if (this.platform.is('android') || this.platform.is('ios')) {
                this.statusBar.overlaysWebView(false);
                this.statusBar.styleLightContent();
                const token = await this.fcm.getToken();
                const resRegister = await this.notificationService.registerDevice({
                    userId: this.userService.getUser().id,
                    deviceType: this.platform.is('android') ? 1 : 2,
                    identifier: this.device.uuid,
                    pushId: token
                });

                if (resRegister.data && !resRegister.data.isError) {
                    localStorage.setItem('n_id', resRegister.result.id);
                    localStorage.setItem('p_id', token);
                }

                this.fcm.onNotification().subscribe(data => {
                    if (data.wasTapped) {
                        console.log("Received in background");
                    } else {
                        console.log("Received in foreground");
                    }
                });

                this.fcm.onTokenRefresh().subscribe(async token => {
                    const resRegister = await this.notificationService.registerDevice({
                        userId: this.userService.getUser().id,
                        deviceType: this.platform.is('android') ? 1 : 2,
                        identifier: this.device.uuid,
                        pushId: token
                    })
                    if (resRegister.data && !resRegister.data.isError) {
                        localStorage.setItem('n_id', resRegister.result.id);
                        localStorage.setItem('p_id', token);
                    }
                });

                this.fcm.hasPermission().then(hasPermission => {
                    if (hasPermission) {
                        console.log("Has permission!");
                    }
                })
            }
        })
    }

    getPermission() {
        this.androidPermissions.checkPermission(
            this.androidPermissions.PERMISSION.READ_PHONE_STATE
        ).then(res => {
            if (res.hasPermission) {
            } else {
                this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(res => {
                    console.log("Persmission Granted Please Restart App!");
                }).catch(error => {
                    console.log("Error! " + error);
                });
            }
        }).catch(error => {
            console.log("Error! " + error);
        });
    }

    async ngOnInit() {
        if (this.logged) {
            const resEnums = await this.authorizationService.getEnums();
            const promises = [
                this._enumsService.setServiceContractPaymentStatus(resEnums.serviceContractPaymentStatus),
                this._enumsService.setServiceContractStatus(resEnums.serviceContractStatus),
                this._enumsService.setServiceContractType(resEnums.serviceContractType),
                this._enumsService.setSource(resEnums.source),
                this._enumsService.setGender(resEnums.sex),
                this._enumsService.setStage(resEnums.stage),
                this._enumsService.setStatus(resEnums.status),
                this._enumsService.setAction(resEnums.action),
                this._enumsService.setDocumentType(resEnums.documentType),
            ];
            await Promise.all(promises)
        }
    }


}
